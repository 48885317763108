<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  mounted() {
    console.log("Ruta base api => ", process.env.VUE_APP_ROOT_API);
    console.log("ENTORNO => ", process.env.NODE_ENV);
    /*
    (function(b, o, i) {
      b.wO = {
        t: "5f000a3b-82d5-4be2-bd46-52eea5ebd1be", original_session_id: "", widget_platform: 7, uniqueId:"", autoDerivate: false, defaultOpenStatus : false , derivationText: ""}; 
    var e = o.createElement(i); 
    var r = o.getElementsByTagName(i)[0]; 
    e.src = "https://widget-blocks.keos.co/widget/script.js"; 
    r.parentNode.insertBefore(e, r);
    

    setTimeout(()=>{
      $("#pgb-container").hide();
    },4000)
      
    }(window, document, 'script'));*/

    if (process.env.VUE_APP_CHATBOT == "PROD") {
      var metadata =
        typeof epublish !== "undefined"
          ? JSON.stringify(epublish.foundation.userData)
          : "";
      (function (b, o, i) {
        b.wO = {
          t: "af236f6b-4ab8-417f-91f8-da374817a702",
          original_session_id: "",
          widget_platform: 7,
          uniqueId: "",
          autoDerivate: true,
          defaultOpenStatus: false,
          derivationText: "Iniciar",
          metadata: metadata,
        };
        var e = o.createElement(i);
        var r = o.getElementsByTagName(i)[0];
        e.src = "https://widget-blocks.keos.co/widget/script.js";
        r.parentNode.insertBefore(e, r);

        setTimeout(() => {
          $("#pgb-container").hide();
        }, 4000);
      })(window, document, "script");
    } else {
      var metadata =
        typeof epublish !== "undefined"
          ? JSON.stringify(epublish.foundation.userData)
          : "";
      /* (function (b, o, i) {
        b.wO = {
          t: "af236f6b-4ab8-417f-91f8-da374817a702",
          original_session_id: "",
          widget_platform: 7,
          uniqueId: "",
          autoDerivate: true,
          defaultOpenStatus: false,
          derivationText: "Iniciar",
          metadata: metadata,
        };
        var e = o.createElement(i);
        var r = o.getElementsByTagName(i)[0];
        e.src = "https://widget-blocks.keos.co/widget/script.js";
        r.parentNode.insertBefore(e, r);

        setTimeout(() => {
          $("#pgb-container").hide();
        }, 4000);


      })(window, document, "script"); */
    }
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>

